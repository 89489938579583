export const getCardRedirectUrl = (
  type: string,
  code: string,
  link?: string,
) => {
  if (link) return link;

  switch (type) {
    case 'restaurant':
      return `/restaurants/${code}`;

    case 'shop':
      return `/shops/${code}`;

    case 'bars':
      return `/bars/${code}`;

    case 'attraction':
      return `/attractions/${code}`;

    case 'food-court':
      return `/food-court/${code}`;

    case 'entertainment':
      return `/entertainments/${code}`;

    case 'pool':
      return `/pools/${code}`;

    case 'show':
      return `/shows/${code}`;

    case 'placement':
      return `/places/${code}`;

    default:
      return `/page/${code}`;
  }
};
